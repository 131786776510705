import React, { useState } from "react";
import Arrow from "../../assets/images/Arrow.svg";
import Delete from "../../assets/images/Delete.svg";
import Bell from "../../assets/images/bell.svg";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useUserDetails } from "../../api";

export default function Table() {
  const navigate = useNavigate();
  const { id } = useParams();
  const { user, loading, error } = useUserDetails(id);
  const [searchTerm, setSearchTerm] = useState("");

  let tanks = [];
  if (user) {
    tanks = user.tanks;
  }

  const filteredTanks = tanks.filter((tank) =>
    tank.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  console.log(user);

  return (
    <div
      className={`p-5 sm:p-[34px] ${tanks.length > 0 ? "h-fit" : "h-screen"}`}
    >
      <div className="grid grid-cols-12">
        <div className="col-span-12 sm:col-span-6">
          <button onClick={() => navigate("/manageusers")}>
            <ul className="inline-flex items-center space-x-5">
              <li>
                <a href="">
                  <img src={Arrow} className="w-[15px] h-[13px]" alt="" />
                </a>
              </li>
              <li className="f-f-m-p text-3xl text-grey text-center sm:text-start">
                {loading ? "Loading..." : user.name}
              </li>
            </ul>
          </button>
        </div>

        <div className="col-span-12 sm:col-span-6">
          <form className="">
            <div className="relative float-right">
              <div className="absolute inset-y-0 start-0 flex items-center pl-[20px] pointer-events-none">
                <svg
                  className="w-[18px] h-[18px] text-grey"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 20 20"
                >
                  <path
                    stroke="currentColor"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
                  />
                </svg>
              </div>
              <input
                type="search"
                id="default-search"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                className="outline-none block w-[325px] h-[43px] py-[10px] pl-[53px] pr-[20px] f-f-r-p text-lg text-grey rounded-[30px] bg-blue-dark"
                placeholder="Search"
                required
              />
            </div>
          </form>
        </div>
      </div>
      <div className="box-set2 mt-10 p-2 md:p-10">
        <h1 className="f-f-sm-r text-3xl text-grey ">
          {loading ? "----" : user.name}
        </h1>

        <div className="grid grid-cols-12 sm:gap-5 3xl:gap-10 mt-[31px]">
          <div className="col-span-12 3xl:col-span-6">
            {/* inner grid start */}
            <div className="grid grid-cols-12">
              <div className="col-span-4 sm:col-span-6">
                <h4 className="f-f-m-p text-[11px] sm:text-xl text-grey-light">
                  Email
                </h4>
                <h4 className="f-f-m-p text-[11px] sm:text-xl text-grey-light mt-5">
                  Phone
                </h4>
                <h4 className="f-f-m-p text-[11px] sm:text-xl text-grey-light mt-5">
                  Country
                </h4>
              </div>

              <div className="col-span-8 sm:col-span-6">
                <div className="text-end">
                  <h4 className="f-f-m-p text-[11px] sm:text-2xl text-grey">
                    {loading ? "----" : user.email}
                  </h4>
                  <h4 className="f-f-m-p text-[11px] sm:text-2xl text-grey mt-5">
                    {loading ? "----" : user.phoneNumber}
                  </h4>
                  <h4 className="f-f-m-p text-[11px] sm:text-2xl text-grey mt-5">
                    {loading ? "----" : user.country}
                  </h4>
                </div>
              </div>
            </div>
            {/* inner grid end */}
          </div>

          <div className="col-span-12 3xl:col-span-6">
            {/* inner grid start */}
            <div className="grid grid-cols-12 mt-5 sm:mt-0">
              <div className="col-span-5 sm:col-span-6">
                <h4 className="f-f-m-p text-[11px] sm:text-xl text-grey-light">
                  User Id
                </h4>
                <h4 className="f-f-m-p text-[11px] sm:text-xl text-grey-light mt-5">
                  Account Creation
                </h4>
                <h4 className="f-f-m-p text-[11px] sm:text-xl text-grey-light mt-5">
                  Active Status
                </h4>
              </div>

              <div className="col-span-7 sm:col-span-6">
                <div className="text-end">
                  <h4 className="f-f-m-p text-[11px] sm:text-2xl text-grey">
                    {loading ? "----" : user._id}
                  </h4>
                  <h4 className="f-f-m-p text-[11px] sm:text-2xl text-grey mt-5">
                    {loading ? "----" : user.accountCreationDate}
                  </h4>
                  <h4 className="f-f-m-p text-[11px] sm:text-2xl text-grey mt-5">
                    {loading ? "----" : user.activeStatus}
                  </h4>
                </div>
              </div>
            </div>
            {/* inner grid end */}
          </div>
        </div>
      </div>
      {filteredTanks.length > 0 ? (
        <div className="grid grid-cols-12 gap-5 sm:gap-10 mt-10">
          {filteredTanks.map((tank) => {
            return (
              <div className="col-span-12 xl:col-span-6" key={tank.name}>
                <div className="box-set2 p-3 sm:p-10">
                  {/* inner grid start */}
                  <div className="grid grid-cols-12">
                    <div className="col-span-5">
                      <h1 className="f-f-m-p text-[11px] sm:text-xl text-grey-light mt-5">
                        Tank Name
                      </h1>

                      <h6 className="f-f-m-p text-[11px] sm:text-xl text-grey-light mt-5">
                        Level
                      </h6>
                      <h6 className="f-f-m-p text-[11px] sm:text-xl text-grey-light mt-5">
                        Tank Size
                      </h6>
                      <h6 className="f-f-m-p text-[11px] sm:text-xl text-grey-light mt-5">
                        Alarm
                      </h6>
                      <h6 className="f-f-m-p text-[11px] sm:text-xl text-grey-light mt-5">
                        Battery
                      </h6>
                      <h6 className="f-f-m-p text-[11px] sm:text-xl text-grey-light mt-5">
                        Signal
                      </h6>
                      <h6 className="f-f-m-p text-[11px] sm:text-xl text-grey-light mt-5">
                        Height
                      </h6>
                      <h6 className="f-f-m-p text-[11px] sm:text-xl text-grey-light mt-5">
                        Mac Address
                      </h6>
                      <h6 className="f-f-m-p text-[11px] sm:text-xl text-grey-light mt-5">
                        Last Updated
                      </h6>
                    </div>

                    <div className="col-span-7">
                      <div className="text-end">
                        <h6 className="f-f-m-p text-[11px] sm:text-xl text-grey-light mt-5">
                          {tank.name}
                        </h6>
                        <h6 className="f-f-m-p text-[11px] sm:text-xl text-grey-light mt-5">
                          {tank.level}
                        </h6>
                        <h6 className="f-f-m-p text-[11px] sm:text-xl text-grey-light mt-5">
                          {tank.size}kg
                        </h6>
                        <h6 className="f-f-m-p text-[11px] sm:text-xl text-grey-light mt-5">
                          {tank.alarm}
                        </h6>
                        <h6 className="f-f-m-p text-[11px] sm:text-xl text-grey-light mt-5">
                          {tank.battery}%
                        </h6>
                        <h6 className="f-f-m-p text-[11px] sm:text-xl text-grey-light mt-5">
                          {tank.signalStrength}dBm
                        </h6>
                        <h6 className="f-f-m-p text-[11px] sm:text-xl text-grey-light mt-5">
                          {tank.height}cm
                        </h6>
                        <h6 className="f-f-m-p text-[11px] sm:text-xl text-grey-light mt-5">
                          {tank.mac}
                        </h6>
                        <h6 className="f-f-m-p text-[11px] sm:text-xl text-grey-light mt-5">
                          {tank.lastUpdated}
                        </h6>
                      </div>
                    </div>
                  </div>
                  {/* inner grid end */}
                </div>
              </div>
            );
          })}
        </div>
      ) : null}
    </div>
  );
}
