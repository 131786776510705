import React, { useState } from "react";
import arrowBack from "../../assets/images/arrowack.svg";
import arrowForward from "../../assets/images/arrowforwed.svg";
import { Link } from "react-router-dom";
import { useSupplierUsers } from "../../api";
import { IoNotificationsSharp } from "react-icons/io5";
import SendUsersNotificationDialog from "./send-users-notification-dialog";

export default function Table() {
  const { users } = useSupplierUsers();
  const [currentPage, setCurrentPage] = useState(1);
  const usersPerPage = 10;

  // Calculate total pages
  const totalPages = Math.ceil(users.length / usersPerPage);

  // Get current users
  const currentUsers = users.slice(
    (currentPage - 1) * usersPerPage,
    currentPage * usersPerPage
  );

  // Handle page change
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  return (
    <>
      <div className="p-[20px] sm:p-[34px]">
        <div className="flex items-center justify-between">
          <h1 className="f-f-m-p text-3xl text-grey text-center sm:text-start">
            Manage Users
          </h1>
          <button
            onClick={() => {
              document
                .getElementById("send_users_notification_modal")
                .showModal();
            }}
            className="btn btn-outline btn-sm text-white hover:bg-primary-dark hover:border-none hover:text-white"
          >
            <IoNotificationsSharp color="white" />
            Notify Users
          </button>
        </div>

        <div className="h-screen">
          <div className="border border-blue-dark rounded mt-8">
            <div className="table-bg relative overflow-x-auto rounded rounded-t">
              <table className="w-full">
                <thead className="border-b border-blue-dark">
                  <tr className="text-left">
                    <td className="pl-5 py-[10px] f-f-r-r text-tiny text-grey min-w-[200px] lg:min-w-fit 3xl:min-w-[246px]">
                      Name
                    </td>
                    <td className="px-4 2xl:px-10 py-[10px] f-f-r-r text-tiny text-grey min-w-[200px] lg:min-w-fit 3xl:min-w-[184px]">
                      Phone
                    </td>
                    <td className="px-4 2xl:px-10 py-[10px] f-f-r-r text-tiny text-grey min-w-[200px] lg:min-w-fit 3xl:min-w-[184px]">
                      Email
                    </td>
                    <td className="px-4 2xl:px-10 py-[10px] f-f-r-r text-tiny text-grey min-w-[200px] lg:min-w-fit 3xl:min-w-[234px]">
                      Account Creation
                    </td>
                    <td className="px-4 2xl:px-10 py-[10px] f-f-r-r text-tiny text-grey min-w-[200px] lg:min-w-fit 3xl:min-w-[234px]">
                      Active Status
                    </td>
                    {/* <td className="px-4 2xl:px-10 py-[10px] f-f-r-r text-tiny text-grey min-w-[200px] lg:min-w-fit 3xl:min-w-[184px]">
                    Subscription
                  </td> */}
                  </tr>
                </thead>
                <tbody>
                  {currentUsers.map((user) => (
                    <tr
                      key={user._id}
                      className="border-b border-blue-dark items-center"
                    >
                      <td className="pl-5 py-4">
                        <Link
                          to={`/manageusers/${user._id}`}
                          className="f-f-m-r text-base text-grey"
                        >
                          {user.name}
                        </Link>
                      </td>
                      <td className="px-4 2xl:px-10 py-4 f-f-r-r text-base text-grey-light">
                        {user.phoneNumber}
                      </td>
                      <td className="px-4 2xl:px-10 py-4 f-f-r-r text-base text-grey-light">
                        {user.email}
                      </td>
                      <td className="px-4 2xl:px-10 py-4 f-f-r-r text-base text-grey-light">
                        {user.accountCreationDate}
                      </td>
                      <td className="px-4 2xl:px-10 py-[10px] f-f-r-r text-tiny text-grey min-w-[200px] lg:min-w-fit 3xl:min-w-[234px]">
                        {user.activeStatus}
                      </td>
                      {/* <td className="px-4 2xl:px-10 py-4">
                      {user.subscription ? (
                        <button
                          className={`w-[100px] py-[5px] px-[10px] rounded-[10px] border text-base ${
                            user.subscription.status
                              ? "border-green f-f-m-p  text-green"
                              : "border-red text-red"
                          } `}
                        >
                          {user.subscription.status &&
                          user.subscription.type === "paid"
                            ? "Paid"
                            : user.subscription.status &&
                              user.subscription.type === "default"
                            ? "Free Trial"
                            : "Expired"}
                        </button>
                      ) : null}
                    </td> */}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            <nav
              className="w-full py-4 sm:px-[34px]"
              aria-label="Table navigation"
            >
              <div className="grid grid-cols-12 items-center">
                <div className="col-span-12 sm:col-span-6">
                  <ul className="inline-flex -space-x-px rtl:space-x-reverse h-[36px]">
                    <li>
                      <button
                        onClick={() => handlePageChange(currentPage - 1)}
                        disabled={currentPage === 1}
                        className="flex items-center justify-center px-3 h-[36px] ms-0 leading-tight text-grey f-f-r-r text-tiny bg-transparent border border-blue-dark rounded-l-[4px]"
                      >
                        <ul className="inline-flex">
                          <li>
                            <img
                              src={arrowBack}
                              className="w-[14px] h-[16px]"
                              alt="Previous"
                            />
                          </li>
                          <li className="ml-[3px]">Previous</li>
                        </ul>
                      </button>
                    </li>
                    {Array.from({ length: totalPages }, (_, index) => (
                      <li key={index}>
                        <button
                          onClick={() => handlePageChange(index + 1)}
                          className={`flex items-center justify-center px-3 h-[36px] leading-tight ${
                            currentPage === index + 1
                              ? "text-blue-600 border border-blue-dark"
                              : "text-grey f-f-r-r text-tiny bg-transparent border border-blue-dark"
                          }`}
                        >
                          {index + 1}
                        </button>
                      </li>
                    ))}
                    <li>
                      <button
                        onClick={() => handlePageChange(currentPage + 1)}
                        disabled={currentPage === totalPages}
                        className="flex items-center justify-center px-3 h-[36px] leading-tight text-grey f-f-r-r text-tiny bg-transparent border border-blue-dark rounded-r-[4px]"
                      >
                        <ul className="inline-flex">
                          <li>Next</li>
                          <li className="ml-[3px]">
                            <img
                              src={arrowForward}
                              className="w-[14px] h-[16px]"
                              alt="Next"
                            />
                          </li>
                        </ul>
                      </button>
                    </li>
                  </ul>
                </div>
                <div className="col-span-12 sm:col-span-6 text-center sm:text-end mt-4 sm:mt-0">
                  <ul className="inline-flex space-x-4 items-center">
                    <li>
                      <h2 className="text-grey f-f-r-r text-tiny">PAGE</h2>
                    </li>
                    <li>
                      <select
                        value={currentPage}
                        onChange={(e) =>
                          handlePageChange(Number(e.target.value))
                        }
                        className="rounded-sm outline-none w-[59px] h-[36px] bg-transparent border border-grey-dark text-grey f-f-r-r text-tiny px-3"
                      >
                        {Array.from({ length: totalPages }, (_, index) => (
                          <option key={index} value={index + 1}>
                            {index + 1}
                          </option>
                        ))}
                      </select>
                    </li>
                    <li>
                      <h2 className="text-grey f-f-r-r text-tiny">
                        OF {totalPages}
                      </h2>
                    </li>
                  </ul>
                </div>
              </div>
            </nav>
          </div>
        </div>
      </div>
      <SendUsersNotificationDialog />
    </>
  );
}
