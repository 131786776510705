import React, { useState, useEffect } from "react";
import arrowBack from "../../assets/images/arrowack.svg";
import arrowForward from "../../assets/images/arrowforwed.svg";
import { useSupplierOrders } from "../../api";

export default function StatisticsTable() {
  const { orders } = useSupplierOrders("completed");
  const [currentPage, setCurrentPage] = useState(1);
  const [displayedOrders, setDisplayedOrders] = useState([]);
  const ordersPerPage = 5;
  const totalPages = Math.ceil(orders.length / ordersPerPage);

  useEffect(() => {
    const startIndex = (currentPage - 1) * ordersPerPage;
    const endIndex = startIndex + ordersPerPage;
    setDisplayedOrders(orders.slice(startIndex, endIndex));
  }, [currentPage, orders]);

  const handlePreviousPage = () => {
    setCurrentPage((prev) => Math.max(prev - 1, 1));
  };

  const handleNextPage = () => {
    setCurrentPage((prev) => Math.min(prev + 1, totalPages));
  };

  const handlePageSelect = (event) => {
    setCurrentPage(Number(event.target.value));
  };

  return (
    <div className="border border-blue-dark rounded-[15px] mt-10">
      <div className="table-bg relative overflow-x-auto rounded-t-[15px]">
        <table className="w-full">
          <thead className="border-b border-blue-dark">
            <tr className="text-left">
              <td className="px-4 2xl:px-10 py-[18px] f-f-r-r text-tiny text-grey min-w-[200px] lg:min-w-fit 3xl:min-w-[234px]">
                Customer Name
              </td>
              <td className="px-4 2xl:px-10 py-[18px] f-f-r-r text-tiny text-grey min-w-[150px] lg:min-w-fit 3xl:min-w-[234px]">
                Tank Size
              </td>
              <td className="px-4 2xl:px-10 py-[18px] f-f-r-r text-tiny text-grey min-w-[200px] lg:min-w-fit 3xl:min-w-[234px]">
                Status
              </td>
              <td className="px-4 2xl:px-10 py-[18px] f-f-r-r text-tiny text-grey min-w-[150px] lg:min-w-fit 3xl:min-w-[234px]">
                Total Cost
              </td>
              <td className="px-4 2xl:px-10 py-[18px] f-f-r-r text-tiny text-grey min-w-[150px] lg:min-w-fit 3xl:min-w-[234px]">
                Payment Type
              </td>
              <td className="pl-[34px] py-[18px] f-f-r-r text-tiny text-grey min-w-[200px] lg:min-w-fit 3xl:min-w-[234px]">
                Payout Status
              </td>
            </tr>
          </thead>
          <tbody>
            {displayedOrders.map((order) => (
              <tr
                key={order._id}
                className="border-b border-blue-dark items-center"
              >
                <td className="px-4 2xl:px-10 py-6 f-f-r-r text-base text-grey">
                  {order.customerInfo.name}
                </td>
                <td className="px-4 2xl:px-10 py-6 f-f-r-r text-base text-grey-light">
                  {order.cylinderSize} kg
                </td>
                <td className="px-4 2xl:px-10 py-6 f-f-r-r text-base text-grey-light">
                  {order.orderStatus}
                </td>
                <td className="px-4 2xl:px-10 py-6 f-f-r-r text-base text-grey-light">
                  {order.price}
                </td>
                <td className="pl-[34px] py-6 f-f-r-r text-base text-grey">
                  {order.paymentType}
                </td>
                <td className="pl-[34px] py-6 f-f-r-r text-base text-grey">
                  {order.paidOut === true ? "Yes" : "No"}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <nav className="w-full py-6 sm:px-[34px]" aria-label="Table navigation">
        <div className="grid grid-cols-12 items-center">
          <div className="col-span-12 sm:col-span-6">
            <ul className="inline-flex -space-x-px rtl:space-x-reverse h-[36px]">
              <li>
                <button
                  onClick={handlePreviousPage}
                  className="flex items-center justify-center px-3 h-[36px] ms-0 leading-tight text-grey f-f-r-r text-tiny bg-transparent border border-blue-dark rounded-l-[4px]"
                  disabled={currentPage === 1}
                >
                  <ul className="inline-flex">
                    <li>
                      <img
                        src={arrowBack}
                        className="w-[14px] h-[16px]"
                        alt="Previous"
                      />
                    </li>
                    <li className="ml-[3px]">Previous</li>
                  </ul>
                </button>
              </li>
              {Array.from({ length: totalPages }, (_, i) => (
                <li key={i}>
                  <button
                    onClick={() => setCurrentPage(i + 1)}
                    className={`flex items-center justify-center px-3 h-[36px] leading-tight f-f-r-r text-tiny border border-blue-dark ${
                      currentPage === i + 1 ? "text-blue-600" : "text-grey"
                    } ${currentPage === i + 1 ? "bg-white" : "bg-transparent"}`}
                  >
                    {i + 1}
                  </button>
                </li>
              ))}
              <li>
                <button
                  onClick={handleNextPage}
                  className="flex items-center justify-center px-3 h-[36px] leading-tight text-grey f-f-r-r text-tiny bg-transparent border border-blue-dark rounded-r-[4px]"
                  disabled={currentPage === totalPages}
                >
                  <ul className="inline-flex">
                    <li>Next</li>
                    <li className="ml-[3px]">
                      <img
                        src={arrowForward}
                        className="w-[14px] h-[16px]"
                        alt="Next"
                      />
                    </li>
                  </ul>
                </button>
              </li>
            </ul>
          </div>
          <div className="col-span-12 sm:col-span-6 text-center sm:text-end mt-4 sm:mt-0">
            <ul className="inline-flex space-x-4 items-center">
              <li>
                <h2 className="text-grey f-f-r-r text-tiny">PAGE</h2>
              </li>
              <li>
                <select
                  className="rounded-sm outline-none w-[59px] h-[36px] bg-transparent border border-grey-dark text-grey f-f-r-r text-tiny px-3"
                  value={currentPage}
                  onChange={handlePageSelect}
                >
                  {Array.from({ length: totalPages }, (_, i) => (
                    <option key={i} value={i + 1}>
                      {i + 1}
                    </option>
                  ))}
                </select>
              </li>
              <li>
                <h2 className="text-grey f-f-r-r text-tiny">OF {totalPages}</h2>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </div>
  );
}
