import React from "react";
import Card from "./Card";
import StatisticsTable from "./StatisticsTable";
import { Link } from "react-router-dom";
import TanksGraph from "./Tanksgraph";
import { useSupplierOrders } from "../../api";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  ArcElement,
  Title,
  BarElement,
  Tooltip,
  Legend,
} from "chart.js";
import { Line, Doughnut } from "react-chartjs-2";

ChartJS.register(
  CategoryScale,
  ArcElement,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const calculatePercentage = (count, total) =>
  total ? (count / total) * 100 : 0;

const centerTextPlugin = (percentage) => {
  return {
    id: "centerText",
    afterDatasetsDraw(chart) {
      const { ctx, width, height } = chart;
      ctx.save();

      const fontSize = (height / 114).toFixed(2);
      ctx.font = `${fontSize}em sans-serif`;
      ctx.textBaseline = "middle";

      const text = `${percentage.toFixed(0)}%`;
      const textX = Math.round((width - ctx.measureText(text).width) / 2);
      const textY = height / 2;

      ctx.fillStyle = "#FFFFFF";
      ctx.fillText(text, textX, textY);
      ctx.restore();
    },
  };
};

const doughnutOptions = {
  cutout: "70%",
  plugins: {
    legend: { display: false },
    tooltip: { enabled: false },
  },
};

const Hero = () => {
  const { orders } = useSupplierOrders();
  const completedOrders = useSupplierOrders("completed");
  const processingOrders = useSupplierOrders("processing");
  const waitingOrders = useSupplierOrders("waiting");
  const canceledOrders = useSupplierOrders("canceled");

  const orderTypes = [
    {
      label: "Completed Orders",
      orders: completedOrders.orders,
      className: "ordercrd",
      percentage: calculatePercentage(
        completedOrders.orders.length,
        orders.length
      ),
    },
    {
      label: "Orders in Process",
      orders: processingOrders.orders,
      className: "ordercrd-2",
      percentage: calculatePercentage(
        processingOrders.orders.length,
        orders.length
      ),
    },
    {
      label: "Waiting Orders",
      orders: waitingOrders.orders,
      className: "ordercrd-3",
      percentage: calculatePercentage(
        waitingOrders.orders.length,
        orders.length
      ),
    },
    {
      label: "Canceled Orders",
      orders: canceledOrders.orders,
      className: "crd1",
      percentage: calculatePercentage(
        canceledOrders.orders.length,
        orders.length
      ),
    },
  ];

  const doughnutData = (data) => ({
    labels: ["Completed", "In Progress", "Not Started"],
    datasets: [
      {
        data: [data],
        backgroundColor: ["#7858f4"],
        hoverOffset: 1,
        borderWidth: 0,
      },
    ],
  });

  return (
    <div className="p-[20px] sm:px-[34px] sm:py-[40px]">
      <Card />
      <div className="grid grid-cols-12 gap-3 sm:gap-6 mt-[20px] h-full">
        <div className="col-span-12 xl:col-span-8">
          <TanksGraph />
        </div>
        <div className="col-span-12 xl:col-span-4">
          <div className="grid grid-cols-12 gap-3 sm:gap-5">
            {orderTypes.map((type, index) => (
              <div
                key={index}
                className="col-span-12 sm:col-span-6 lg:col-span-3 xl:col-span-12"
              >
                <Link to="/gasorders">
                  <div className={`${type.className} py-[22px] px-[20px]`}>
                    <div className="flex justify-between items-center">
                      <div className="col-span-7">
                        <h2 className="text-grey f-f-sm-p text-3xl">
                          {type.orders.length ?? "----"}
                        </h2>
                        <h3 className="text-grey-light f-f-r-p text-[11px] 2xl:text-sm mt-3">
                          {type.label}
                        </h3>
                      </div>
                      <div style={{ width: "90px", height: "90px" }}>
                        {orders.length > 0 && type.orders.length > 0 ? (
                          <Doughnut
                            data={doughnutData(type.percentage)}
                            options={doughnutOptions}
                            plugins={[centerTextPlugin(type.percentage)]}
                          />
                        ) : (
                          <p>----</p>
                        )}
                      </div>
                    </div>
                  </div>
                </Link>
              </div>
            ))}
          </div>
        </div>
      </div>
      <StatisticsTable />
    </div>
  );
};

export default Hero;
