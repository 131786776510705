import React from "react";
import Group from "../../assets/images/Group 21.svg";
import Tanks from "../../assets/images/Group 239599.svg";
import Active from "../../assets/images/Group 21active.svg";
import { Link } from "react-router-dom";
import {
  useActiveUsers,
  useInactiveUsers,
  useTotalTanks,
  useTotalUsers,
} from "../../api";

export default function Card() {
  const { totalUsers } = useTotalUsers();
  const { totalTanks } = useTotalTanks();
  const { activeUsers } = useActiveUsers();
  const { inactiveUsers } = useInactiveUsers();

  return (
    <div>
      <div className="grid grid-cols-12 gap-3 sm:gap-6     ">
        <div className=" col-span-12 sm:col-span-6 lg:col-span-3">
          <Link to="/manageusers">
            <div className=" crd1 py-[22px] px-[20px] ">
              {/* inner grid started  */}
              <div className="grid grid-cols-12    items-center ">
                <div className=" col-span-7">
                  <h2 className=" text-grey f-f-sm-p text-3xl  ">
                    {totalUsers ?? "----"}
                  </h2>
                  <h3 className=" text-grey-light f-f-r-p text-[11px] 2xl:text-sm mt-3 ">
                    Total Users
                  </h3>
                </div>
                <div className=" col-span-5">
                  <img
                    src={Group}
                    className=" w-[50px] 2xl:w-[84px] h-auto 3xl:h-[84px] ml-auto "
                  />
                </div>
              </div>
              {/* inner grid ended */}
            </div>
          </Link>
        </div>
        <div className=" col-span-12 sm:col-span-6 lg:col-span-3">
          <Link to="/cylinders">
            <div className=" crd2 py-[22px] px-[20px] ">
              {/* inner grid started  */}
              <div className="grid grid-cols-12    items-center ">
                <div className=" col-span-7">
                  <h2 className=" text-grey f-f-sm-p text-3xl  ">
                    {totalTanks ?? "----"}
                  </h2>
                  <h3 className=" text-grey-light f-f-r-p text-[11px] 2xl:text-sm mt-3 ">
                    Total Tanks
                  </h3>
                </div>
                <div className=" col-span-5">
                  <img
                    src={Tanks}
                    className=" w-[50px] 2xl:w-[84px] h-auto 3xl:h-[84px] ml-auto "
                  />
                </div>
              </div>
              {/* inner grid ended */}
            </div>
          </Link>
        </div>
        <div className=" col-span-12 sm:col-span-6 lg:col-span-3">
          <Link to="/manageusers">
            <div className=" crd3 py-[22px] px-[20px] ">
              {/* inner grid started  */}
              <div className="grid grid-cols-12    items-center ">
                <div className=" col-span-7">
                  <h2 className=" text-grey f-f-sm-p text-3xl  ">
                    {inactiveUsers ?? "----"}
                  </h2>
                  <h3 className=" text-grey-light f-f-r-p text-[11px] 2xl:text-sm mt-3 ">
                    Inactive Users
                  </h3>
                </div>
                <div className=" col-span-5">
                  <img
                    src={Group}
                    className=" w-[50px] 2xl:w-[84px] h-auto 3xl:h-[84px] ml-auto "
                  />
                </div>
              </div>
              {/* inner grid ended */}
            </div>
          </Link>
        </div>
        <div className=" col-span-12 sm:col-span-6 lg:col-span-3">
          <Link to="/manageusers">
            <div className=" crd4 py-[22px] px-[20px] ">
              {/* inner grid started  */}
              <div className="grid grid-cols-12    items-center ">
                <div className=" col-span-7">
                  <h2 className=" text-grey f-f-sm-p text-3xl  ">
                    {activeUsers ?? "----"}
                  </h2>
                  <h3 className=" text-grey-light f-f-r-p text-[11px] 2xl:text-sm mt-3 ">
                    Total Active Users
                  </h3>
                </div>
                <div className=" col-span-5">
                  <img
                    src={Active}
                    className=" w-[50px] 2xl:w-[84px] h-auto 3xl:h-[84px] ml-auto "
                  />
                </div>
              </div>
              {/* inner grid ended */}
            </div>
          </Link>
        </div>
      </div>
    </div>
  );
}
