import React from "react";
import Group from "../../assets/images/dollorsign.svg";
import Tanks from "../../assets/images/ww21.svg";
import Active from "../../assets/images/Group 2333a.svg";
import { useSupplierSales } from "../../api";

export default function Card() {
  const currentMonth = new Date().toISOString().slice(0, 7); // Format: "YYYY-MM"
  const lastMonth = new Date(new Date().setMonth(new Date().getMonth() - 1))
    .toISOString()
    .slice(0, 7); // Last month format: "YYYY-MM"

  const {
    totalSales: currentMonthSales,
    loading: currentLoading,
    error: currentError,
  } = useSupplierSales(currentMonth);
  const {
    totalSales: lastMonthSales,
    loading: lastLoading,
    error: lastError,
  } = useSupplierSales(lastMonth);
  const {
    totalSales: allTimeSales,
    loading: allTimeLoading,
    error: allTimeError,
  } = useSupplierSales();

  return (
    <div>
      <div className="grid grid-cols-12 gap-3 sm:gap-6 ">
        <div className=" col-span-12 sm:col-span-6 lg:col-span-4">
          <div className=" crd1 py-[22px] px-[20px] ">
            {/* inner grid started  */}
            <div className="grid grid-cols-12    items-center ">
              <div className=" col-span-7">
                <h2 className=" text-grey f-f-sm-p text-3xl  ">
                  R {allTimeLoading ? "----" : allTimeSales.toFixed(2)}
                </h2>
                <h3 className=" text-grey-light f-f-r-p text-[11px] 2xl:text-sm mt-3 ">
                  All time sales
                </h3>
              </div>
              <div className=" col-span-5">
                <img
                  src={Group}
                  className=" w-[50px] 2xl:w-[84px] h-auto 3xl:h-[84px] ml-auto "
                />
              </div>
            </div>
            {/* inner grid ended */}
          </div>
        </div>
        <div className=" col-span-12 sm:col-span-6 lg:col-span-4">
          <div className=" crd2 py-[22px] px-[20px] ">
            {/* inner grid started  */}
            <div className="grid grid-cols-12    items-center ">
              <div className=" col-span-7">
                <h2 className=" text-grey f-f-sm-p text-3xl  ">
                  R {lastLoading ? "----" : lastMonthSales.toFixed(2)}
                </h2>
                <h3 className=" text-grey-light f-f-r-p text-[11px] 2xl:text-sm mt-3 ">
                  Last month sales
                </h3>
              </div>
              <div className=" col-span-5">
                <img
                  src={Tanks}
                  className=" w-[50px] 2xl:w-[84px] h-auto 3xl:h-[84px] ml-auto "
                />
              </div>
            </div>
            {/* inner grid ended */}
          </div>
        </div>
        <div className=" col-span-12 sm:col-span-6 lg:col-span-4">
          <div className=" crd3 py-[22px] px-[20px] ">
            {/* inner grid started  */}
            <div className="grid grid-cols-12    items-center ">
              <div className=" col-span-7">
                <h2 className=" text-grey f-f-sm-p text-3xl  ">
                  R {currentLoading ? "----" : currentMonthSales.toFixed(2)}
                </h2>
                <h3 className=" text-grey-light f-f-r-p text-[11px] 2xl:text-sm mt-3 ">
                  Current month sales
                </h3>
              </div>
              <div className=" col-span-5">
                <img
                  src={Active}
                  className=" w-[50px] 2xl:w-[84px] h-auto 3xl:h-[84px] ml-auto "
                />
              </div>
            </div>
            {/* inner grid ended */}
          </div>
        </div>
      </div>
    </div>
  );
}
