import React, { useState } from "react";
import CloseIcon from "../../assets/images/Frame 98.svg";
import { useNotifyUsers } from "../../api/index"; // Adjust the path based on your file structure

export default function SendUsersNotificationDialog() {
  const [title, setTitle] = useState("");
  const [message, setMessage] = useState("");

  // Initialize the custom hook with your base URL
  const { notifyUsers, loading, error, success } = useNotifyUsers();

  const handleSendNotification = async () => {
    await notifyUsers(title, message);
    if (!loading && success) {
      // Optionally, reset fields or close the dialog after successful notification
      setTitle("");
      setMessage("");
      document.getElementById("send_users_notification_modal").close(); // Close the dialog
    }
  };

  return (
    <dialog
      id="send_users_notification_modal"
      className="modal text-start custom-scrollbar"
    >
      <div className="modal-box sm:min-w-[531px] createdlg p-4 sm:p-[30px] ">
        <h3 className="text-grey f-f-sm-r text-2xl sm:text-3xl">Send Alert</h3>

        <div className="flex items-center gap-3 mt-4 flex-col">
          <div className="w-full">
            <h6 className="mt-[30px] f-f-m-r text-sm sm:text-base text-grey">
              Title
            </h6>
            <input
              type="text"
              value={title}
              onChange={(e) => {
                setTitle(e.target.value);
              }}
              className="w-full h-[40px] sm:h-[44px] rounded border border-blue-dark mt-2 bg-transparent f-f-r-r text-[11px] sm:text-tiny text-grey-light p-3 outline-none"
              placeholder="Enter title"
            />
          </div>
          <div className="w-full">
            <h6 className="f-f-m-r text-sm sm:text-base text-grey">Message</h6>
            <textarea
              rows={6}
              value={message}
              onChange={(e) => {
                setMessage(e.target.value);
              }}
              className="w-full rounded resize-none border border-blue-dark mt-2 bg-transparent f-f-r-r text-[11px] sm:text-tiny text-grey-light p-3 outline-none"
              placeholder="Enter message"
            />
          </div>
        </div>

        {/* Display loading state, success message, or error message */}
        {loading && <p>Sending...</p>}
        {error && <p style={{ color: "red" }}>{error}</p>}
        {success && <p style={{ color: "green" }}>{success}</p>}

        <div className="flex justify-end mt-2">
          <button
            onClick={handleSendNotification}
            className="btn bg-primary-dark f-f-sm-n text-tiny sm:text-base text-white-light hover:bg-primary-dark"
            disabled={loading} // Disable button while loading
          >
            Send
          </button>
        </div>

        <form method="dialog">
          <button className="absolute top-[30px] right-[26px]">
            <img src={CloseIcon} className="w-[22px] h-[22px]" alt="Close" />
          </button>
        </form>
      </div>
    </dialog>
  );
}
