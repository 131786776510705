import React from "react";
import Card from "./Card";
import Tanksgraph from "./Tanksgraph";
import { useSalesKgsPerMonth, useSupplierOrders } from "../../api/index";
import galyan from "../../assets/images/galyan.svg";
import PiechartsStatis from "./PiechartsStatis";
import StatisticsTable from "./StatisticsTable";
import Icon2 from "../../assets/images/Icon2.svg";
import { Link, useNavigate } from "react-router-dom";
import TanksGraph from "./Tanksgraph";

export default function Hero() {
  const { orders } = useSupplierOrders("completed");
  const navigate = useNavigate();

  // Calculates the available balance for the supplier by summing the price of all completed orders
  // where the payment type is 'Card' and the order has not been paid out (paidOut is false).
  const availableBal = orders
    .filter((order) => order.paymentType === "Card" && order.paidOut === false)
    .reduce((total, order) => total + parseFloat(order.price), 0);

  const currentMonth = new Date().toISOString().slice(0, 7); // Format: "YYYY-MM"
  const lastMonth = new Date(new Date().setMonth(new Date().getMonth() - 1))
    .toISOString()
    .slice(0, 7); // Last month format: "YYYY-MM"

  // Current month sales in kilograms
  const {
    salesKgs: currentMonthSalesKgs,
    loading: currentLoading,
    error: currentError,
  } = useSalesKgsPerMonth(currentMonth);

  // Last month sales in kilograms
  const {
    salesKgs: lastMonthSalesKgs,
    loading: lastLoading,
    error: lastError,
  } = useSalesKgsPerMonth(lastMonth);

  // All-time sales in kilograms (no month parameter)
  const {
    salesKgs: allTimeSalesKgs,
    loading: allTimeLoading,
    error: allTimeError,
  } = useSalesKgsPerMonth();

  return (
    <div className=" p-[20px] sm:px-[34px] sm:py-[40px] ">
      <div className="grid grid-cols-12 mb-10  ">
        <div className="col-span-12 sm:col-span-6">
          <h1 className="f-f-m-p text-3xl text-grey text-center sm:text-start">
            Earnings
          </h1>
        </div>

        <div className="col-span-12 sm:col-span-6">
          <div className="text-center mt-3 sm:mt-0 sm:text-end space-x-5">
            {/* <button className=' w-[97px] h-[41px] rounded-[10px] bg-primary-dark'>
                            <ul className='inline-flex items-center space-x-2'>
                                <li><img src={Icon2} className='w-4 h-4' alt="" /></li>
                                <li className='f-f-sm-n text-base text-white-light'>Filter</li>
                            </ul>
                        </button> */}
          </div>
        </div>
      </div>

      <div className="grid grid-cols-12 gap-3 sm:gap-6 mt-[20px] h-full   ">
        <div className=" col-span-12 2xl:col-span-9 h-full flex flex-col ">
          <Card />
          <div className=" mt-3 sm:mt-6 h-full">
            <TanksGraph />
          </div>
        </div>
        <div className=" col-span-12 2xl:col-span-3 ">
          {/* card grid started */}
          <div className="grid grid-cols-12 gap-3 sm:gap-5 ">
            <div className="  col-span-12 sm:col-span-6 xl:col-span-3 2xl:col-span-12  ">
              <div className=" availbalnce py-[22px] px-[20px] 3xl:h-[156px] relative h-full ">
                <h2 className=" text-grey f-f-sm-p text-3xl ">
                  Available Balance
                </h2>
                <h3 className=" text-grey f-f-sm-p text-3xl pt-[6px] ">
                  R {availableBal}
                </h3>

                <div className="grid grid-cols-12 gap-3   ">
                  <div className="  col-span-12 xl:col-span-5  ">
                    <h3 className=" f-f-r-p text-grey text-xs ">
                      Orders Paid online
                    </h3>
                  </div>
                  <div className=" col-span-12  xl:col-span-7 text-right  ">
                    {/* <button className=" text-grey f-f-r-p text-[11px] 2xl:text-sm w-[129px] h-[28px] border border-grey  rounded-[15px] ">
                      Withdraw Amount
                    </button> */}
                  </div>
                </div>
              </div>
            </div>
            <div className="  col-span-12 sm:col-span-6 xl:col-span-3 2xl:col-span-12 h-full ">
              <div className=" crd1 py-[22px] px-[20px] h-full ">
                {/* inner grid started  */}
                <div className="grid grid-cols-12    items-center h-full ">
                  <div className=" col-span-7">
                    <h2 className=" text-grey f-f-sm-p text-3xl  ">
                      {currentLoading ? "----" : currentMonthSalesKgs} kg
                    </h2>
                    <h3 className=" text-grey-light f-f-r-p text-[11px] 2xl:text-sm mt-3 ">
                      Current month lpg sales in kg
                    </h3>
                  </div>
                  <div className=" col-span-5">
                    <img
                      src={galyan}
                      className=" w-[50px] 2xl:w-[84px] h-auto 3xl:h-[84px] ml-auto "
                    />
                  </div>
                </div>
                {/* inner grid ended */}
              </div>
            </div>
            <div className="  col-span-12 sm:col-span-6 xl:col-span-3 2xl:col-span-12 h-full ">
              <div className=" crd1 py-[22px] px-[20px] h-full ">
                {/* inner grid started  */}
                <div className="grid grid-cols-12    items-center h-full ">
                  <div className=" col-span-7">
                    <h2 className=" text-grey f-f-sm-p text-3xl  ">
                      {lastLoading ? "----" : lastMonthSalesKgs} kg
                    </h2>
                    <h3 className=" text-grey-light f-f-r-p text-[11px] 2xl:text-sm mt-3 ">
                      Last month lpg sales in kg
                    </h3>
                  </div>
                  <div className=" col-span-5">
                    <img
                      src={galyan}
                      className=" w-[50px] 2xl:w-[84px] h-auto 3xl:h-[84px] ml-auto "
                    />
                  </div>
                </div>
                {/* inner grid ended */}
              </div>
            </div>
            <div className="  col-span-12 sm:col-span-6 xl:col-span-3 2xl:col-span-12 h-full ">
              <div className=" crd1 py-[22px] px-[20px] h-full ">
                {/* inner grid started  */}
                <div className="grid grid-cols-12    items-center h-full ">
                  <div className=" col-span-7">
                    <h2 className=" text-grey f-f-sm-p text-3xl  ">
                      {" "}
                      {allTimeLoading ? "----" : allTimeSalesKgs} kg
                    </h2>
                    <h3 className=" text-grey-light f-f-r-p text-[11px] 2xl:text-sm mt-3 ">
                      All time lpg sales in kg
                    </h3>
                  </div>
                  <div className=" col-span-5">
                    <img
                      src={galyan}
                      className=" w-[50px] 2xl:w-[84px] h-auto 3xl:h-[84px] ml-auto "
                    />
                  </div>
                </div>
                {/* inner grid ended */}
              </div>
            </div>
          </div>
        </div>
      </div>

      <StatisticsTable />
    </div>
  );
}
